import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, Link, useStaticQuery } from "gatsby"

export default function MediumCard({
  image,
  title,
  subtitle,
  summary,
  to,
  href,
}) {
  const data = useStaticQuery(graphql`
    query {
      strapiDetails {
        groupLink: daccaGroupSiteLink
      }
    }
  `)

  const details = data.strapiDetails

  return (
    <div className="h-full flex flex-col sm:grid sm:grid-cols-2 md:flex md:flex-col">
      <div>
        {console.log(image)}
        <GatsbyImage
          alt={image.image.alt}
          image={getImage(image.image.localFile)}
          className="w-full h-0 pb-1/1"
        />
      </div>
      <div className="flex flex-col p-6 bg-gray-300 h-full">
        <p className="montserrat-medium-8-200">{subtitle}</p>
        <h3 className="playfair-no-upper">{title}</h3>
        <p className="regular my-4">
          {summary.length > 160 ? (
            <> {summary.substr(0, 159)}&hellip; </>
          ) : (
            summary
          )}
        </p>
        {to && (
          <Link to={to} className="mt-auto">
            <button className="font-light text-sm border-b border-gray-500 pb-0.5 border-black tracking-90 uppercase">
              Read More
            </button>
          </Link>
        )}
        {href && (
          <a href={details.groupLink + href} className="mt-auto">
            <button className="font-light text-sm border-b border-gray-500 pb-0.5 transition-colors duration-300 hover:text-black hover:border-black tracking-90 uppercase">
              Read More
            </button>
          </a>
        )}
      </div>
    </div>
  )
}
