import { Link } from "gatsby"
import React from "react"

export default function PrimaryButton(props) {
  return (
    <button className="text-lg tracking-90 uppercase px-4 py-2 font-light border border-white">
      <Link to={props.to}>{props.text}</Link>
    </button>
  )
}
