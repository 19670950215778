import React from "react"
import MediumCard from "./MediumCard"

export default function ArticleReel({ articles }) {
  return (
    <section className="section-container pt-0 px-2">
      <h2 className="playfair-title">Journal/Blog/News</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-3xl">
        {articles.map(article => (
          <MediumCard
            id={article.slug}
            title={article.title}
            subtitle={article.subtitle}
            href={article.slug}
            summary={article.summary}
            image={article.cover}
          />
        ))}
      </div>
    </section>
  )
}
