import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import PlayfairTitle from "./PlayfairTitle"
import ProjectTile from "./ProjectTile"
import { graphql, useStaticQuery } from "gatsby"

export default function GroupJournal({ articles, ...props }) {
  const data = useStaticQuery(graphql`
    query {
      strapiDetails {
        groupLink: daccaGroupSiteLink
      }
    }
  `)

  const details = data.strapiDetails

  return (
    <section className="section-container-white pt-4 px-2">
      <PlayfairTitle title="Dacca Group journal"></PlayfairTitle>
      <div className="max-w-3xl grid grid-cols-2 sm:grid-cols-5 gap-2 w-full">
        {articles.map(article => (
          <a href={details.groupLink + "/" + article.slug}>
            <ProjectTile
              image={
                <GatsbyImage
                  alt={
                    article.thumbnail
                      ? article.thumbnail.image.alt
                      : article.cover.image.alt
                  }
                  image={
                    article.thumbnail
                      ? getImage(article.thumbnail.image.localFile)
                      : getImage(article.cover.image.localFile)
                  }
                  placeholder="blurred"
                  className="object-cover h-full w-full"
                />
              }
              title={article.title}
            />
          </a>
        ))}
      </div>
    </section>
  )
}
