import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function GroupConversation({ article }) {
  const data = useStaticQuery(graphql`
    query {
      strapiDetails {
        groupLink: daccaGroupSiteLink
      }
    }
  `)

  const details = data.strapiDetails

  const member = article

  return (
    <section className="section-container-white pt-4 px-2">
      <h2 className="playfair-title">DACCA Group Conversations</h2>
      <div
        className="grid grid-cols-1 sm:grid-cols-2 max-w-3xl"
        key={member.slug}
      >
        <div className="">
          <GatsbyImage
            alt={member.cover.alt || member.cover.image.alt || null}
            image={
              getImage(member.cover.image.localFile) ||
              getImage(member.cover.localFile)
            }
            className="w-full h-0 pb-1/1"
          />
        </div>
        <div className="p-6 bg-gray-300 h-full flex flex-col justify-end items-start">
          <h3 className="playfair-no-upper">{member.title}</h3>
          <p className="font-medium text-xs leading-5 tracking-60 my-4">
            {member.summary}
          </p>
          <a href={details.groupLink + member.slug}>
            <button className="font-light text-sm border-b border-gray-500 pb-0.5 transition-colors duration-300 hover:text-black hover:border-black tracking-90 uppercase">
              Read More
            </button>
          </a>
        </div>
      </div>
    </section>
  )
}
