import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

import Layout from "../../components/Layout"
import PlayfairTitle from "../../components/PlayfairTitle"
import PrimaryButton from "../../components/PrimaryButton"
import ProjectTile from "../../components/ProjectTile"
import ArticleReel from "../../components/ArticleReel"
import GroupConversation from "../../components/GroupConversation"
import GroupJournal from "../../components/GroupJournal"
import StrapiRender from "../../components/StrapiRender"

export default function LandingPage({ data }) {
  const landingPage = data.landingPage
  const projects = data.landingPage.similarProjects
  const journalArticles = landingPage.journalReel
  const conversationArticle = landingPage.conversationArticle
  const groupArticles = landingPage.group_articles

  return (
    <Layout
      Seo={{ title: landingPage.Title, description: landingPage.Support }}
      heroMain={
        <div className="flex flex-col items-center justify-end w-full h-full text-white pb-12">
          <div className="pb-2 text-lg tracking-90 uppercase flex flex-row items-center">
            <div className="bg-white w-10 md:w-20 h-px" />
            <p className="text-xl mx-3 font-light"> {landingPage.Subtitle} </p>
            <div className="bg-white w-10 md:w-20 h-px" />
          </div>
          <h1 className="pb-16 text-center font-serif text-5xl tracking-90">
            {landingPage.Title}
          </h1>
          <PrimaryButton
            text="Read more"
            to="/projects/queanbeyan-bowling-club"
          />
        </div>
      }
      heroSupport={landingPage.Support}
      heroImage={
        <GatsbyImage
          style={{
            gridArea: "1/1",
            filter: "brightness(90%)",
          }}
          layout="fullWidth"
          image={getImage(landingPage.image.localFile)}
          alt={landingPage.image.alternativeText}
          loading="eager"
        />
      }
      content={
        <>
          <section className="section-container pt-0 px-2">
            <div className="container flex flex-col items-center text-center space-y-12">
              <StrapiRender node={landingPage.body} />
            </div>
          </section>
          <section className="section-container pt-0 px-2">
            <PlayfairTitle title="Similar Projects" />
            <div className="max-w-3xl w-full grid grid-cols-2 sm:grid-cols-5 gap-2">
              {projects.slice(0, 5).map(project => (
                <Link to={"/projects/" + project.slug} key={project.id}>
                  <ProjectTile
                    title={project.title}
                    subtitle={project.thumbnailSubtitle}
                    image={
                      <GatsbyImage
                        image={
                          project.thumbnailImage
                            ? getImage(project.thumbnailImage.localFile)
                            : getImage(project.cover.image.localFile)
                        }
                        alt={project.cover.image.alt}
                        className="object-cover h-full w-full"
                      />
                    }
                  />
                </Link>
              ))}
            </div>
          </section>
          <ArticleReel articles={journalArticles} />
          <GroupConversation article={conversationArticle} />
          <GroupJournal articles={groupArticles} />
        </>
      }
    />
  )
}

export const query = graphql`
  query LandingPageQuery($slug: String!) {
    landingPage: strapiLandingPages(slug: { eq: $slug }) {
      Title
      Subtitle
      Support
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        alternativeText
      }
      body
      childHtmlRehype {
        htmlAst
      }
      similarProjects {
        title
        thumbnailSubtitle
        slug
        cover {
          image {
            alt: alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        thumbnailImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      journalReel {
        title
        subtitle
        summary
        slug
        cover {
          image {
            alt: alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      conversationArticle {
        title
        subtitle
        summary
        slug
        cover {
          image {
            alt: alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      group_articles {
        title
        slug
        cover {
          image {
            alt: alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        thumbnail {
          image {
            alt: alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
