import React from "react"

export default function ProjectTile({ image, title, subtitle }) {
  return (
    <div className="flex flex-col group">
      <div className=" mb-2 pb-4/3 relative overflow-hidden">
        <div className="absolute h-full w-full transition-transform duration-500 group-hover:scale-105">
          {image}
        </div>
      </div>
      <div className="tiny-title transition-color duration-500 group-hover:text-black/80">
        <p>{title}</p>
        <p>{subtitle}</p>
      </div>
    </div>
  )
}
